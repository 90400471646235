import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import customAxiosNm from '../../config/axios-refresh-token';
import { Box, Button, Col, Flex, Input, Loader, Modal, Text, Wrapper } from '../../components/Ui';
import { InputField } from '../../components/Ui/Input/InputAddress/InputAddress';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../theme/mixin';

function VerifyCode() {
  const { code } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        if (code) {
          setLoading(true);
          const res = await customAxiosNm.post('/utility/verify-code/' + code);
          setData(res.data);
          setLoading(false);
        }
      } catch (error) {
        console.log({ error });

        setError(error.response.data.error);
        setLoading(false);
      }
    })();
  }, [code]);
  const verifyHanlder = async () => {
    try {
      await customAxiosNm.post('/utility/verify-code-complete/' + code);
      const res = await customAxiosNm.post('/utility/verify-code/' + code);
      setData(res.data);
      console.log({ res });
    } catch (error) {
      setError(error.message);
      console.log({ error });
    }
  };
  const handleCode = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const c = formData.get('code');
    if (c.trim()) {
      history.push(`/verify-code/${c}`);
    }
  };
  if (!code) {
    return (
      <Wrapper>
        <form onSubmit={handleCode}>
          <Flex direction='column' gap='20px' justify='center' align='center'>
            <CustomInputField name='code' placeholder='Inserisce il codice' />

            <Button active type='submit'>
              vai
            </Button>
          </Flex>
        </form>
      </Wrapper>
    );
  }
  return (
    <>
      {loading ? (
        <Loader show={loading} />
      ) : !error ? (
        <Wrapper>
          <Box>
            <Flex direction='column' gap='20px'>
              <Text as='h1'>Verifica il codice</Text>
              {data &&
                Object.entries(data.rows).map(([key, value]) => (
                  <Text>
                    <strong>{key}:</strong> {value}
                  </Text>
                ))}

              {data && data.available ? (
                <Button active onClick={verifyHanlder}>
                  Verifica e consegna il premio
                </Button>
              ) : (
                <Flex
                  justify='center'
                  align='center'
                  style={{
                    backgroundColor: '#f8dbdb',
                    padding: '15px',
                    border: '1px solid #EF4342',
                    borderRadius: '5px',
                    width: '100%',
                  }}
                >
                  {data?.delivery}
                </Flex>
              )}
            </Flex>
          </Box>
        </Wrapper>
      ) : (
        <Wrapper>
          <Box
            style={{
              padding: '15px',
              margin: '100px auto',
              width: '100%',
              backgroundColor: '#f8dbdb',
              padding: '15px',
              border: '1px solid #EF4342',
              textAlign: 'center',
            }}
          >
            <Text>{error}</Text>
          </Box>
        </Wrapper>
      )}
    </>
  );
}
const CustomInputField = styled(InputField)`
  width: 90%;
  ${respondTo.sm`
    width: 50%;
  `}
`;
export default VerifyCode;
