import React, { useEffect } from 'react';
import { Loader } from '../../components/Ui';
import { useParams, useHistory } from 'react-router-dom';
import customaxios from '../../config/axios-refresh-token';
import { useDispatch } from 'react-redux';
import * as actionCreators from '../../store/actions';

const WelfareLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      customaxios
        .post('/welfare/ott-login', { token: token })
        .then(res => {
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('from_wp', true);
          dispatch(actionCreators.bootCms());
          dispatch(actionCreators.getUserInfo());
          history.push('/');
        })
        .catch(err => {
          localStorage.removeItem('token');
          history.push('/auth/login');
          window.location.reload();
        });
    }
  }, [token, history, dispatch]);

  return <Loader initial show={true} />;
};

export default WelfareLogin;
