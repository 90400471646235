import React, { useEffect } from 'react';

const RedirectView = ({ type }) => {
  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) return 'Windows Phone';
    if (/android/i.test(userAgent)) return 'Android';
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'iOS';

    return 'unknown';
  };

  const appLinks = {
    iOS: {
      coil: 'https://apps.apple.com/us/app/coil-con-te/id6740341249',
      wuerth: 'https://apps.apple.com/app/w%C3%BCrth-fidelity/id1670096928',
    },
    Android: {
      coil: 'https://play.google.com/store/apps/details?id=com.fabrico.coil',
      wuerth: 'https://play.google.com/store/apps/details?id=com.wuerth.fidelity',
    },
  };

  useEffect(() => {
    const os = getMobileOperatingSystem();
    if (appLinks[os] && appLinks[os][type]) {
      window.location.replace(appLinks[os][type]);
    } else {
      window.location.replace('/');
    }
  }, [type, appLinks]);

  return <></>;
};

export default RedirectView;
