import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Fade from '../../components/Fade/Fade';
import { removeFromWishlist, addToCart, addToCartClearError } from '../../store/actions/wishlist';
import { Text, Button, Flex, Box, Col, Modal, Loader, Wrapper } from '../../components/Ui';
import Product from '../../components/product';
import { closeOrderModal } from '../../store/actions/products';
import Recensione from '../../components/recensione';
import ProductInline from '../../components/product/ProductInline';
import ReactShadowScroll from 'react-shadow-scroll';
import ProductDetails from '../../components/product/productDetails';

const CustomFlexProducts = styled.div`
  width: 100%;
`;
const Wishlist = () => {
  const dispatch = useDispatch();
  const [isReviews, setIsReviews] = useState(false);
  const [puntiAbbastanza, setpuntiAbbastanza] = useState(false);
  const wishlist = useSelector(state => state.user.user.wishlist);
  const error = useSelector(state => state.user.cartError);
  const {
    product,
    orderModal,
    loading: singleProductLoading,
  } = useSelector(state => state.product);
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});

  return (
    <>
      <Fade>
        <Wrapper style={{ paddingTop: '5px' }}>
          {wishlist?.length > 0 ? (
            <>
              <Text as='h1' size={40} className='mb-20'>
                {option?.menuSettings?.wishlist?.label}
              </Text>
              <CustomFlexProducts>
                {wishlist.map(item => (
                  <ProductInline
                    key={item.id}
                    product={item}
                    catalogId={item.catalog_id}
                    setIsReviews={setIsReviews}
                    setpuntiAbbastanza={setpuntiAbbastanza}
                  />
                ))}
              </CustomFlexProducts>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Text as='h1' size={40} className='mb-20'>
                Preferiti
              </Text>
              <Text size={18}>La tua lista preferiti è vuota.</Text>
            </div>
          )}
        </Wrapper>
      </Fade>
      <Modal
        show={orderModal.isOpen || puntiAbbastanza || Boolean(error)}
        close={() => {
          dispatch(addToCartClearError());
          dispatch(closeOrderModal());

          setpuntiAbbastanza(false);
        }}
        isFixed={!isReviews}
        alert={Boolean(error)}
      >
        {singleProductLoading && !isReviews ? (
          <Loader show={singleProductLoading} />
        ) : Boolean(error) ? (
          <Text>{error}</Text>
        ) : isReviews ? (
          <Recensione product={product} />
        ) : (
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '80vh' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {!puntiAbbastanza && orderModal.isOpen ? (
              <ProductDetails
                product={product}
                setIsReviews={setIsReviews}
                setpuntiAbbastanza={setpuntiAbbastanza}
              />
            ) : (
              <Text> Non hai abbastanza punti</Text>
            )}
          </ReactShadowScroll>
        )}
      </Modal>
    </>
  );
};

const WishlistWrapper = styled.div``;

export default Wishlist;
