import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Flex, Text } from '../../components/Ui';

const ValidateEmailApp = () => {
  const logo = useSelector(state => state.app.config.logo);

  return (
    <ValidateContainer>
      <Flex direction='column' gap='20px' align='center'>
        <img src={logo} alt='' />
        <Text as='h2' size={30} className='title'>
          Email confermata.
        </Text>
        <Text>Il tuo indirizzo email è stato confermato</Text>
        <Text>Ora puoi accedere all'app e tue credenziali</Text>
      </Flex>
    </ValidateContainer>
  );
};

const ValidateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .title {
    font-size: 35px !important;
  }
  div {
    text-align: center;

    img {
      margin-bottom: 15px;
      max-width: 400px;
    }

    h1 {
      margin-bottom: 15px;
    }
  }
`;

export default ValidateEmailApp;
