import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import styled from 'styled-components/macro';

export const InputField = styled.input`
  color: ${props => props.theme.colore_testo_campi};
  padding: 0 15px;
  background-color: ${props => props.theme.input.bg};
  border-radius: 4px;
  width: ${props => (props.type === 'checkbox' ? '20px' : 'auto')};
  flex-grow: 1;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  opacity: ${props => (props.readonly ? '0.3' : '1')};
  border: 2px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg;
    }};
  &::placeholder {
    color: #47545d59;
  }
`;
const fields = {
  country: ['country'],
  address1: ['route'],
  civico: ['street_number'],
  district: ['administrative_area_level_2'],
  city: ['administrative_area_level_3', 'locality'],
  zipcode: ['postal_code'],
};
const InputAddress = props => {
  const [address, setAddress] = React.useState(props.value);

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    let addressComponents = results[0].address_components;

    if (
      addressComponents.filter(
        item => item.types[0] === 'administrative_area_level_3' || item.types[0] === 'locality',
      ).length === 2
    ) {
      addressComponents = addressComponents.filter(item => item.types[0] !== 'locality');
    }

    for (const key in fields) {
      const sanMarinoCondition =
        key !== 'zipcode' &&
        key !== 'civico' &&
        addressComponents.find(component => component.types.includes('country'))?.long_name ===
          'San Marino';

      if (sanMarinoCondition) {
        props.form[key].value = 'San Marino';
        props.form[key].valid = true;
      } else {
        const component = addressComponents.find(component =>
          component.types.some(type => fields[key].includes(type)),
        );

        if (component) {
          const valueKey = fields[key].includes('locality') ? 'long_name' : 'short_name';
          if (props.form[key]) {
            props.form[key].value = component[valueKey] || '';
            props.form[key].valid = true;
          }
        } else {
          if (props.form[key]) {
            props.form[key].value = '';
            props.form[key].valid = false;
          }
        }
      }
    }

    setAddress(
      props.form['address1'].value === 'San Marino'
        ? 'San Marino'
        : addressComponents.find(component => component.types.includes('route'))?.long_name,
    );

    props.onChange({
      target: {
        name: props.name,
        value:
          props.form['address1'].value === 'San Marino'
            ? 'San Marino'
            : addressComponents.find(component => component.types.includes('route'))?.long_name,
      },
    });
  };
  const handleChange = address => {
    setAddress(address);
    props.onChange({ target: { name: props.name, value: address } });
    props.form.district.value = '';
    props.form.district.valid = false;
    if (!address) {
      props.form.address1.value = '';
      props.form.address1.valid = false;
      props.form.civico.value = '';
      props.form.civico.valid = false;
      props.form.city.value = '';
      props.form.city.valid = false;
      props.form.zipcode.value = '';
      props.form.zipcode.valid = false;
      props.form.country.value = '';
      props.form.country.valid = false;
    }
  };
  return (
    <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div style={{ width: '100%', position: 'relative', zIndex: '10' }}>
            <InputField {...props} {...getInputProps()} style={{ width: '100%' }} />
            <div
              style={{ position: 'absolute', width: '100%', top: '100%', left: '0', zIndex: '10' }}
            >
              {loading ? <div>...loading</div> : null}

              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? '#ddd' : '#eee',
                  padding: '10px',
                  cursor: 'pointer',
                };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default InputAddress;
